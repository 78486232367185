import React, { useState, useEffect, useRef } from "react"
import clsx from "clsx"

import Dropdown from "./dropdown"

const Plans = ({ plans }) => {
  const [isComplete, setIsComplete] = useState(false)
  const [isLifetime, setIsLifetime] = useState(false)
  const ref1 = useRef(null)
  const ref2 = useRef(null)

  const handleClickOutside = event => {
    if (ref1.current && !ref1.current.contains(event.target)) {
      setIsComplete(false)
    }
    if (ref2.current && !ref2.current.contains(event.target)) {
      setIsLifetime(false)
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true)
    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, [])

  return (
    <div className="section-plans">
      <div className="container">
        <div className="plan-wrapper">
          {plans.map((plan, i) => {
            return (
              <div
                key={i}
                className={clsx("plan-box", { popular: plan.popular })}
              >
                {plan.popular && <div className="label">Most popular</div>}
                <h2 className="heading-2">{plan.title}</h2>
                <p>{plan.description}</p>
                <ul>
                  {plan.planDetails.map((details, j) => {
                    return <li key={j}>{details.item}</li>
                  })}
                </ul>
                {plan.complete && (
                  <div className="btn-holder" ref1={ref1}>
                    <button
                      className="btn-purple"
                      onClick={() => setIsComplete(!isComplete)}
                    >
                      {plan.btnText}
                    </button>
                    {isComplete && <Dropdown data={plan.planData} />}
                  </div>
                )}
                {plan.lifetime && (
                  <div className="btn-holder" ref2={ref2}>
                    <button
                      className="btn-purple"
                      onClick={() => setIsLifetime(!isLifetime)}
                    >
                      {plan.btnText}
                    </button>
                    {isLifetime && <Dropdown data={plan.planData} />}
                  </div>
                )}

                <p className="text-center">{plan.description2}</p>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Plans
