import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import Plans from "../components/plans"

const plans = [
  {
    complete: true,
    popular: true,
    title: "Complete",
    description: "$50 per month or $500 per year",
    planDetails: [
      {
        item: <>Unlimited provisioning actions</>,
      },
      {
        item: (
          <>
            <span className="text-line-through">Up to 250</span> Unlimited
            subscriptions managed
          </>
        ),
      },
      {
        item: (
          <span className="text-line-through">
            Additional subscriptions for $0.03 each per month
          </span>
        ),
      },
      {
        item: <span className="text-bold">Billsby customers save 40%</span>,
      },
    ],
    btnText: "Start with a one month free trial",
    description2:
      "Works with Billsby and Chargebee - Recurly and Stripe Billing coming soon",
    planData: {
      billsby: {
        productId: 6352,
        planId: 8794,
        cycleId: null,
      },
      chargebee: {
        productId: 6353,
        planId: 8796,
        cycleId: null,
      },
    },
  },
  {
    lifetime: true,
    title: "Lifetime(ish) Deal",
    description: "$500 for 5 years service",
    planDetails: [
      {
        item: <>Unlimited provisioning actions</>,
      },
      {
        item: <><span className="text-line-through">Up to 250</span> Unlimited subscriptions managed</>,
      },
      {
        item: <><span className="text-line-through">Additional subscriptions for $0.03 each per month</span></>,
      },
      {
        item: <span className="text-bold">Billsby customers save 40%</span>,
      },
    ],
    btnText: "Sign up today",
    description2: "30 day money back guarantee",
    planData: {
      billsby: {
        productId: 6352,
        planId: 8795,
        cycleId: null,
      },
      chargebee: {
        productId: 6353,
        planId: 8797,
        cycleId: null,
      },
    },
  },
]

const Pricing = () => {
  return (
    <Layout className="pricing">
      <SEO title="Pricing" />
      <Banner
        pricingBanner
        title="Save hours activating and deactivating customers each month for less than the cost of your daily trip to Starbucks"
      />
      <Plans plans={plans} />
    </Layout>
  )
}

export default Pricing
